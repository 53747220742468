import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function MultipleSelect({names, currOrderList}) {
  //let defVal=currOrderList.Enquiry||[];
  //console.log(defVal)
  const [personName, setPersonName] = React.useState([]);
  console.log(personName);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let arr=[]
    arr=typeof value === 'string' ? value.split(',') : value;

    arr=arr.map((val)=>val.split('-')[1])
    console.log(arr);
    currOrderList.Enquiry=[...arr]
    console.log(currOrderList)
  };
React.useEffect(() => {
  console.log(currOrderList.Enquiry);
  if(currOrderList.Enquiry=='' ||currOrderList.Enquiry==undefined){
    currOrderList.Enquiry=[]
  }
  let arr=[]
  for(let i=0;i<names.length;i++){
    if(currOrderList.Enquiry.indexOf(names[i].split('-')[1])>=0 ){
      arr.push(names[i])
    }
  }
  setPersonName([...arr])
}, [])

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        {/* <InputLabel id="demo-multiple-checkbox-label">Writers</InputLabel> */}
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={
            personName
            }
          onChange={handleChange}
          input={<OutlinedInput label="Writers" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names?.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}