import Button from "../Button/Button.component"
import Input from "../Input/Input.component"
import Label from "../Label/Label.component"
import { useFormik } from 'formik'
import { signUpSchema, updateSchema } from "../../validationSchema";
import Error from "../Error/Error.component";
import { authSignUp, authUpdate, authVerify } from '../../services/api';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../../redux/slices/userData";
import { Autocomplete, Box, styled, Tab, Tabs, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MessageModal from "../MessageModal/MessageModal.component";
import { messageActions } from "../../redux/slices/message";


const GenderList = [{ label: "---Not Specified---" }, { label: "Male" }, { label: "Female" }]
const RoleList = [{ label: "---Not Specified---" }, { label: 'admin' }, { label: "writer" }]

function UserUpdateForm({ openModal, userData }) {

  const [value, setValue] = useState(new Date());
  const message = useSelector((state => state.message.message))
  const messageType = useSelector((state => state.message.messageType))




  const CustomDate = styled(DatePicker)`
  width:285px;
  margin: 1px 24px;
  padding:0px;
  div{
    height: 45px;
    outline:none;
    padding:0px;
    &:focus {
    outline: none;
    }
  }
  outline:none;
  `
  const CustomGender = styled(Autocomplete)`
  width:285px;
  margin: 1px 24px;
  padding:0px;
  div{
    height: 45px;
    outline:none;
    padding:0px;
    &:focus {
    outline: none;
    }
  }
  outline:none;

  
  `
  const currUser = useSelector((state => state.userData.userData))

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      fname: userData.name.split(' ')[0] || '',
      lname: userData.name.split(' ')[1] || '',
      email: userData.email || '',
      contact: userData.contact || '',
      location: userData.location || '',
      dob: userData.dob || '',
      gender: userData.gender || '',
      course: userData.course || '',
      role: userData.role || ''

    },
    validationSchema: updateSchema,
    onSubmit: async values => {
      console.log(values);

      let myRes = await authUpdate(values);
      console.log(myRes);
      if (currUser.role !== "admin")
        dispatch(userDataActions.setUserData(myRes.data.data));
      if (myRes.status == 200)
        openModal(myRes.data.message, 'success')


      else
        openModal(myRes.data.message, 'error')

    },
  });

  return (
    <>
      {
        message && <MessageModal
          status={messageType || 'success'}
          message={message}
          setMessage={() => dispatch(messageActions.clearMessage())}
        />
      }
      <div className='1px ms-start  overflow-hidden'
        style={{ width: "90vw", maxWidth: "718px" }}
      >

        <div className=' bg-white flex justify-center'>

          <Box sx={{ margin: "20px" }}>

            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-wrap">
                <div>
                  <Label val='First Name' />
                  <Input placeholder={'Enter your First Name'}
                    name="fname"
                    value={formik.values.fname}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {
                    formik.touched.fname &&
                    <Error>{formik.errors.fname}</Error>

                  }
                  <Label val='Last Name' />
                  <Input placeholder={'Enter your Last Name'}
                    name="lname"
                    value={formik.values.lname}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {
                    formik.touched.lname &&
                    <Error>{formik.errors.lname}</Error>

                  }

                  <Label val='Location' />
                  <Input placeholder={'Enter your Location'}
                    name="location"
                    value={formik.values.location}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {
                    formik.touched.location &&
                    <Error>{formik.errors.location}</Error>

                  }
                  <Label val='Gender' />

                  <CustomGender

                    disablePortal
                    id="combo-box-demo"
                    //   value={selTaskName}
                    onChange={(newValue) => {

                      formik.setFieldValue("gender", newValue.target.textContent)
                      console.log(formik.values);
                    }}
                    value={formik.values.gender.length ? formik.values.gender : GenderList[0].label}
                    options={GenderList}
                    sx={{
                      width: 285,
                      padding: 0
                    }}
                    renderInput={(params) => <TextField {...params} label="" />} />

                </div>
                <div>

                  <Label val='Course' />
                  <Input placeholder={'Enter your Location'}
                    name="course"
                    value={formik.values.course}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {
                    formik.touched.course &&
                    <Error>{formik.errors.course}</Error>

                  }
                  {currUser.role == 'admin' &&
                    <>
                      <Label val='Role' />
                      {/* <Input placeholder={'Enter your Role'}
            name="role"
            value={formik.values.role}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          /> */}
                      <CustomGender

                        disablePortal
                        id="combo-box-demo"
                        //   value={selTaskName}
                        onChange={(newValue) => {

                          formik.setFieldValue("role", newValue.target.textContent)
                          console.log(formik.values);
                        }}
                        value={formik.values.role.length ? formik.values.role : RoleList[0].label}
                        options={RoleList}
                        sx={{
                          width: 285,
                          padding: 0
                        }}
                        renderInput={(params) => <TextField {...params} label="" />} />
                    </>
                  }
                  {/* {
            formik.touched.role &&
            <Error>{formik.errors.course}</Error>

          } */}
                  <Label val='Email' />
                  <Input
                    placeholder={'Enter your Email'}
                    name='email'
                    type="email"
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {
                    formik.touched.email &&
                    <Error>{formik.errors.email}</Error>

                  }

                  <Label val='Contact' />
                  <Input placeholder={'Enter your Mobile No.'}
                    type="number"
                    name="contact"
                    onBlur={formik.handleBlur}
                    value={formik.values.contact}
                    onChange={formik.handleChange}
                  />
                  {
                    formik.touched.contact &&
                    <Error>{formik.errors.contact}</Error>

                  }
                  <Label val='Date of Birth' />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDate
                      disableFuture
                      label=""
                      openTo="year"
                      views={['year', 'month', 'day']}
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                        formik.setFieldValue("dob", new Date(newValue))
                      }}
                      sx={{
                        width: 285,
                        padding: 0
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                </div>

              </div>

              <div style={{ display: "block", margin: "auto", width: "fit-content" }}>
                <Button val={"Update"}
                  isValid={!formik.dirty || !formik.isValid}
                />

              </div>



            </form>
          </Box>

        </div>
      </div>
    </>





  )
}

export default UserUpdateForm