import TextArea from 'antd/es/input/TextArea'
import React from 'react'
import './Input.style.scss'
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function Input({ TextField, className = '', ...otherProps }) {
    return (

        <div className={`w-fit ${className}`}>
            {TextField ?
                <textarea
                    className={` input mx-6 font-light text-base p-2 px-4 rounded-md outline-none w-80 h-40  `}
                    {
                    ...otherProps
                    }
                >
                </textarea> :
                <input
                    className='input mx-6 font-light text-base  p-2 px-4 rounded-md  h-fit outline-none w-72'
                    {
                    ...otherProps
                    }
                    type={otherProps.type || 'text'}
                />}
        </div>)
}

export default Input