import ImgCarousel from '../../Components/Carousel/ImgCarousel.component.jsx'
import React from 'react'
import Header from '../../Components/Header/Header.component'
import WhyUs from '../../Components/HomeContainer/WhyUs.component.jsx'
import PriceCalc from '../../Components/PriceCalc/PriceCalc.component.jsx'
import Footer from '../../Components/Footer/Footer.component.jsx'
import ServicesOver from '../../Components/HomeContainer/ServicesOver.component.jsx'
import Features from '../../Components/HomeContainer/Features.component.jsx'
import { useSelector } from 'react-redux'


function Home() {
  const userData = useSelector((state => state.userData.userData))
  
  return (
    <div className=' w-screen h-screen'>

        {
        // Object.keys(userData).length == 0 &&
         <Header></Header>
         }
        <ImgCarousel></ImgCarousel>
        <div className="h-screen pt-4" ></div>
        <WhyUs></WhyUs>
        <ServicesOver><Features/></ServicesOver>
        
        <Footer/>
     
        
    </div>
  )
}

export default Home