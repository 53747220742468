import React from 'react'
import Footer from '../../Components/Footer/Footer.component'
import Header from '../../Components/Header/Header.component'
import UserProfile from '../../Components/User/UserProfile.componenet'

function Profile() {
    return (
        <div style={{display:"flex",flexDirection:"column"}}>
            <Header />
        <UserProfile/>
            <Footer />

        </div>
    )
}

export default Profile