import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: "products",
    initialState: {
        selTID: '',
        selSID: ''
    },
    reducers: {
        setSelTID: (state, {payload}) => {
            state.selTID=payload
        },
        setSelSID: (state, {payload}) => {
            state.selTID='101';
            state.selSID=payload;
        },
       
    }
})

export const productActions = productSlice.actions;
export default productSlice.reducer
