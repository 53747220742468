import './PriceCalc.style.scss'
import Input from "../../Components/Input/Input.component"
import Label from "../../Components/Label/Label.component"
import {
  Box,
  TextField,
  Autocomplete,
  InputLabel,
  Select, Button
} from '@mui/material';
import useHttp from '../../utils/hooks/useHttp';
import { useEffect, useState } from "react";
import ResponsiveDateTimePickers from "./time";
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../redux/slices/cartSlice';
import Login from '../../Pages/Login/Login.component';
import SpringModal from '../Modal/ModalContainer.component';
import { placeOrder } from '../../services/api';
import { messageActions } from '../../redux/slices/message';


function PriceCalc() {
  const cartList=useSelector((state => state.cartItems.item));

const userData = useSelector((state => state.userData.userData))

  const TIDNav = useSelector((state => state.products.selTID))
  const SIDNav = useSelector((state => state.products.selSID))
  const [CatList, setCatList] = useState([]);
  const [SubList, setSubList] = useState([]);
  const [TaskList, setTaskList] = useState([]);
  const [curTask, setCurTask] = useState({});
  const { sendRequest } = useHttp();
  const [value, setValue] = useState(new Date());
  const num = useSelector((state => state.cartItems.item.pages));
  const TaskNameForm = useSelector((state => state.cartItems.item.task));
  const SIDForm = useSelector((state => state.cartItems.item.subject));
  const Price = useSelector((state => state.cartItems.item.price));
  const myDispatch = useDispatch();
  let curSubName = "";
  function openModal(msg, mtype) {
    myDispatch(messageActions.setMessage({ message: msg, messageType: mtype }))
}
  async function  orderHandler(){
    let myRes= await placeOrder({data:[cartList]});
    if (myRes.status == 200)
    openModal(myRes.data.message, 'success')

  else
    openModal(myRes.data.message, 'error')

}
  // updating pages in redux
  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value > 0 && e.target.value <= 250) {
        myDispatch(cartActions.setItem({ key: 'pages', value: e.target.value }));
      }
    }
  };
  useEffect(() => {
    // Calculating deadline and sending to redux
    let deadline = new Date(value);
    let noOfDays = Math.floor(
      (new Date(value) - Date.now()) /
      (1000 * 60 * 60 * 24)
    )
    deadline = deadline.toString();
    myDispatch(cartActions.setItem({ key: 'deadline', value: deadline }))

    // let price be 0
    if (noOfDays < 1 || TaskNameForm.length == 0 || SIDForm.length == 0 || num < 1) {
      myDispatch(cartActions.setItem({ key: 'price', value: 0 }));
      return
    }

    // page price depends on no. of pages
    let pagePrice = num * ((100 - num) / 100)
    if (num > 15)
      pagePrice = num * ((100 - 15) / 100)


    // updating value of subname, Srate and Crate
    let Srate = 0;
    let Crate = 0;
    for (let i = 0; i < SubList.length; i++) {
      if (SubList[i].SID == SIDNav || SubList[i].SID == SIDForm[0]) {
        Srate = SubList[i].SRate;
        Crate = SubList[i].CWeight;
        curSubName = SubList[i].SNAME;

        break;
      }

    }

    //  price formula
    let p = curTask["Req time"] * curTask.TRate * Crate * Srate * pagePrice / noOfDays;

    // price with no discount
    if (noOfDays <= curTask["Req time"]) {
      myDispatch(cartActions.setItem({ key: 'price', value: p }));
    }
    else {
      // price with 15% max discount
      p = curTask.TRate * Crate * Srate * pagePrice;
      let extraDays = noOfDays - curTask["Req time"];
      if (extraDays > 15) { extraDays = 15 }
      let newPrice = (p * ((100 - extraDays) / 100))
      myDispatch(cartActions.setItem({ key: 'price', value: newPrice }));
    }
    // console.log("price set successfully ", Price);
    // console.log(noOfDays + " " + TaskNameForm + " " + SIDForm + " " + num + " " + curTask.TRate + " " + pagePrice + " " + Srate, SIDNav);

  }, [TaskNameForm, SIDForm, num, value, curTask, SubList, SIDNav])




  // handles change in task from nav and form
  async function taskHandler(selTask) {
    console.log(selTask.TName);
    if (selTask.length !== 0)
      // set task
      setCurTask(selTask);



    // finding index of task object and setting curTask
    let idx = TaskList.findIndex((listItem) => listItem.TName == selTask.TName)
    setCurTask(TaskList[idx])


    // fetching Ctype of that task from server.
    await sendRequest({
      method: "GET",
      url: `https://quick2response.com/api/getCategory?val=${TaskList[idx].CType}`
    }, (data) => {
      // setting sub and cat according to task
      setSubList([...data.sub])
      setCatList([...data.cat])

    }, "")

  }

  // get items of task list
  const getTask = async () => {
    await sendRequest({
      method: "GET",
      url: `https://quick2response.com/api/getTask`

    }, (data) => {
      data = data.data;
      // setting task name in redux
      var i;
      for (i = 0; i < data.length; i++) {
        if (data[i]["TID"] == TIDNav) {
          let task = (data[i]['TName']);
          myDispatch(cartActions.setItem({ key: 'task', value: task }));
        }
        // adding label key in data for TName
        data[i].label = data[i]['TName'];
      }
      // setting TaskList
      setTaskList([...data])


    }, "")
  };


  // Useffect 1 to call nav task or redux task name changed.
  useEffect(() => {
    // calling getTask
    getTask()

  }, [TIDNav, TaskNameForm])


  // Useeffect 2 to be called when tasklist changed
  useEffect(() => {
    // setting task value when tasklist is changed
    var i;
    for (i = 0; i < TaskList.length; i++) {
      if (TaskList[i]["TID"] == TIDNav) {
        taskHandler(TaskList[i]);
        let newTask = (TaskList[i]['TName']);
        myDispatch(cartActions.setItem({ key: 'task', value: newTask }));

        break;
      }

    }
  }, [TaskList])
  return (

    <Box>
      {/* <form> */}
        {/* label to display price */}
        <div
          className='p-5'
          style={{
            backgroundColor: 'white',
            width: "55vw",
            minWidth: "330px",
            display: "flex",
            flexWrap: "wrap",
            margin: "auto",
            marginTop: "50px",
            boxShadow: "0px 0px 10px 3px rgba(153,153,153,0.7)",
          }}>
          <div className='w-full '>

            <h1 class="font-medium leading-tight text-5xl mt-0 mb-2 block w-full text-center">Estimate Assignment</h1>
            <div className='m-5 font-med text-2xl text-center border
            mx-6  p-2 px-4 rounded-md outline-none
            '
              style={{
                width: "250px", display: "block",
                margin: "auto"
              }}>
              AUD $
              {Price.toPrecision(4)}
            </div></div>



          {/* Taskname combobox */}
          <div className="m-6">
            <Autocomplete
              onChange={(e) => {

                let task = (e.target.textContent)
                myDispatch(cartActions.setItem({ key: 'task', value: task }));
                taskHandler({ TName: e.target.textContent })
              }}
              disablePortal
              id="combo-box-demo"
              value={TaskNameForm}
              options={TaskList}
              sx={{
                width: 250
              }}
              renderInput={(params) => <TextField {...params} label="Task" />} />
          </div>
          <div>
            {SubList.length !== 0 && <FormControl
              sx={{
                m: 3,
                minWidth: 250
              }}>

              {/* Subject combo box */}
              <InputLabel htmlFor="grouped-native-select">Subject</InputLabel>

              {/* using sub id to know sub name */}
              <Select onChange={(e) => {
                let subjectid = (e.target.value);
                for (let i = 0; i < SubList.length; i++) {
                  if (SubList[i].SID == subjectid) {
                    curSubName = SubList[i].SNAME
                    break;
                  }
                }
                // setting subid subname in redux
                myDispatch(cartActions.setItem({ key: 'subject', value: [subjectid, curSubName] }));
              }} native defaultValue="" value={SIDForm[0]} id="grouped-native-select" label="Grouping">
                <option aria-label="None" /> {[...CatList].map((cat) => {
                  return (
                    <optgroup label={cat.CNAME}>
                      {[...SubList].filter((sub) => sub.CID === cat.CID).map((sub) => {
                        return (
                          <option value={sub.SID}>{sub.SNAME}</option>
                        )
                      })}
                    </optgroup>
                  )
                })}

              </Select>
            </FormControl>
            }


            {/*input for no. of pages */}
          </div>
          {
            curTask?.Unit && curTask?.Unit !== "null" &&
            <Box component="form" className='m-6 p-0 flex 
            textField-center-input'>
              <Button style={{
                backgroundColor: "#2596be",
                color: "white",
                fontSize: "32px",
                padding: "0px",
                borderRadius: "0px",
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px'

              }} onClick={() => {
                if (num > 0 && num <= 250) {
                  let pages = (num - 1);
                  myDispatch(cartActions.setItem({ key: 'pages', value: pages }));
                }
              }}>-</Button>
              <TextField
                sx={{ "& fieldset": { border: 'none' }, }}
                InputProps={{
                  style: { width: "200" },
                }}
                className=""
                type="text"
                id="outlined-basic"
                label={`Number of ${curTask.Unit}`}
                variant="outlined"
                onChange={(e) => handleChange(e)}
                value={num} />
              <Button style={{
                backgroundColor: "#2596be",
                color: "white",
                fontSize: "32px",
                padding: "0px",
                borderRadius: "0px",
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px'
              }} onClick={() => {
                if (num >= 0 && num < 250) {
                  let pages = (num + 1);
                  myDispatch(cartActions.setItem({ key: 'pages', value: pages }));
                }
              }}>+</Button>
            </Box>
          }


          {/* input Deadline picker */}
          <ResponsiveDateTimePickers value={value} setValue={setValue}></ResponsiveDateTimePickers>
          {/* Input for Upload assessment document */}
          <div>
            <Label val='Upload Assessment document' />
            <Input
              name='upload'
              type="file"
              style={{
                width: "250px"
              }}
              // accept='zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
              // convert uploaded file to base64 and update redux
              onChange={(e) => {
                const file = e.target.files[0]
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                  let quesFileDoc = ('upload', { data: reader.result, name: file.name, type: file.type });
                  myDispatch(cartActions.setItem({ key: 'quesFileDoc', value: quesFileDoc }));
                }
              }} />
            {/* <p style={{ 'color': "red", marginLeft: "30px" }}>Only use .zip,.rar,.7zip type file</p> */}
          </div>

          {/* input for Description */}
          <div>
            <Label val='Description' />
            <Input name='msg' type='text' onChange={(e) => {
              let desc = e.target.value;
              myDispatch(cartActions.setItem({ key: 'desc', value: desc }));
            }}
              placeholder={'Enter your message'}
              TextField={true}
              style={{
                width: "250px"
              }}

            />
          </div>

          {/* button add to cart */}
          <div className='flex  w-full justify-center my-5 '>
            <div className=' mx-5'>
              <Button val="Add to cart" style={{
                backgroundColor: "#2596be",
                color: "white",
                opacity: `${Price == 0 ? "0.5" : "1"}`
              }} onClick={() => { myDispatch(cartActions.setFullCartItems()) }}
                disabled={Price == 0}
              > Add to cart
              </Button></div>


            {/* button complete order*/}
            <div className=' mx-5'>
            {Object.keys(userData).length !== 0?

              <Button type="Submit" val="Complete Order" 
              disabled={Price == 0}
              onClick={orderHandler} style={{
                backgroundColor: "#2596be",
                color: "white",
                opacity: `${Price == 0 ? "0.5" : "1"}`
                
              }}>
                Complete Order
              </Button>
              : <Button type="Submit" val="Complete Order" style={{
                
                backgroundColor: "#2596be",
                color: "white",
                opacity: `${Price == 0 ? "0.5" : "1"}`
              }}><SpringModal
                text="Login to complete order"
                disabled={Price == 0}
                style={{
                  padding: "0px !important",
                  opacity: `${Price == 0 ? "0.5" : "1"}`
                }}>
                  <Login></Login>
                </SpringModal></Button>
}
            </div>

          </div>


        </div>
      {/* </form> */}


    </Box>
  )
}

export default PriceCalc