import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PeopleIcon from '@mui/icons-material/People';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QuizIcon from '@mui/icons-material/Quiz';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header.component';
import { useDispatch, useSelector } from 'react-redux';
import { userDataActions } from '../../redux/slices/userData';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge } from '@mui/material';
import { getNotification } from '../../services/api';
const drawerWidth = 240;
let whatsappIcon=document.getElementsByClassName('floating-wpp-button')[0];
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer({ children }) {
  const userData = useSelector((state => state.userData.userData))
  const notificationsData = useSelector((state => state.userData.notifications))

  const UniDispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (whatsappIcon)
        whatsappIcon.style.display = 'none';

    //document.getElementsByClassName('tawk-to')[0].style.display='none';
    let Notify = async () => {

      let myRes = await getNotification();
      UniDispatch(userDataActions.setNotification(myRes.data.data));
      // setNotifications([...myRes.data.data.reverse()])
      // setPgCount(Math.ceil(myRes.data.data.length / 5))
    }
    Notify()

  }, [])

  const navigate = useNavigate();
  return (<>

    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open} style={{ background: "#1c2b4b" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div className=' mt-14 '>
            <Header admin={true} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[...['My Profile', 'Order Enquiry', 'Assigned Orders', 'Orders History', 'Users', 'Notification', 'Logout']].filter((val) => {
            if (val !== 'Users' && val !== 'Order Enquiry' && val !== 'Assigned Orders') {
              return val
            }
            else if (userData.role == 'admin' && val == 'Users') {
              return val
            }
            else if (userData.role == 'writer' && (val == 'Order Enquiry' || val == 'Assigned Orders')) {
              return val
            }


          }).map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => {
                  if (text === 'Logout') {
                    // console.log(whatsappIcon);
                    // whatsappIcon.style.display = 'block';
                    UniDispatch(userDataActions.logout());
                    navigate(`/`)
                    return
                  }

                  navigate(`/${text.replace(' ', '-')}`)
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >

                  {text === 'Logout' && <LogoutIcon />}
                  {text === 'My Profile' && <SentimentSatisfiedAltIcon />}
                  {text === 'Orders History' && <ShoppingCartIcon />}
                  {text === 'Users' && <PeopleIcon />}
                  {text == 'Order Enquiry' && <QuizIcon />}
                  {text == 'Assigned Orders' && <AssignmentIcon />}

                  {text === 'Notification' &&
                    <Badge badgeContent={[...notificationsData]?.filter((val)=>!val.read).length} color="primary">
                      <NotificationsIcon />
                    </Badge>
                  }
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: '-20' }}>
        {/* <DrawerHeader /> */}
        {children}
      </Box>
    </Box>
  </>
  );
}