import { Navigate } from "react-router-dom";


const LoginRoute = ({ isAuthenticated, children }) => {

    if(!isAuthenticated){
        return <Navigate to="/NotFound" replace />
    }
   
    return children
}

export default LoginRoute;