import * as React from 'react';
import { DataGrid} from '@mui/x-data-grid';
import { messageActions } from '../../redux/slices/message';
import { dispatch } from '../../redux/store';
import UserUpdateForm from '../../Components/User/UserUpdateForm.component';
import SpringModal from '../../Components/Modal/ModalContainer.component';

export default function UsersList({ userData }) {
    const [currUserData, setcurrUserData] = React.useState([])
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'course', headerName: 'Course', width: 130 },
        {
            field: 'role',
            headerName: 'Role',
            width: 130,
        },
        {
            field: 'contact',
            headerName: 'Contact',
            width: 130,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 280,
        },
        {
            field: 'location',
            headerName: 'Location',
            width: 250,
        },
        {
            field: "Update",
            headerName: "Update",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    setcurrUserData(userData[params.id-1])
                    console.log(currUserData)
                };
    
                return <button onClick={onClick}
                style={{ background:"#3496e8"}}
                >       <SpringModal
                text="Update "
                sx={{
                    padding: "0px !important",
                    color: "blue"
                }}>
                <UserUpdateForm userData={currUserData} openModal={openModal}></UserUpdateForm>

            </SpringModal></button>;
            }
        },
    
    ];
    function openModal(msg, mtype) {
        dispatch(messageActions.setMessage({ message: msg, messageType: mtype }))
    }
    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={userData}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                checkboxSelection
            />
     
        </div>
    );
}