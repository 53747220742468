import { Typography } from '@mui/material';
import React from 'react';
import bgimage from '../../assets/utils/5ContactUs.jpg';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import './ContactDesc.style.scss';
function ContactDesc() {
  return (
    <div className=' flex-1 bg-gray-800 text-white font-mono  w-screen border' 
    style={{
      maxWidth:"350px",
      height:"100vh",
    }}
     >
     <div style={{ padding:"6px", margin:"10px" }}> 
     <p style={{fontSize:"20px"}}>We would love to hear from you. Our customer friendly team of experts is always ready to help you. We would love to hear from you. 
    </p>
    <div>

  <div className="animate pop" style={{fontSize:"17px",marginTop:"20px"}}> <LocationOnOutlinedIcon/> {'TBD'||'East Canal Road, Mirpur Chowk, Jail Road<br/>Gurdaspur, Punjab, PIN-143521<br/>'}</div>
<div className="animate pop delay-1" style={{marginTop:"20px"}}><CallOutlinedIcon/> +91-94191-06996<br/></div>
<div className="animate pop delay-2" style={{marginTop:"20px"}}><MailOutlinedIcon/> quickresponse1992@gmail.com<br/></div>
</div>
      

</div>
      <img src={bgimage} alt="bgimage" style={{filter:"blur(2px) opacity(20%)"}}/>
      
    </div>
  )
}

export default ContactDesc