import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ContactUs from './Pages/ContactUs/ContactUs'
import Courses from './Pages/Courses/Courses'
import Home from './Pages/Home/Home.component'
import Plagiarism from './Pages/Plagiarism/Plagiarism'
import BookNow from './Pages/BookNow/BookNow'
import Profile from './Pages/Profile/Profile'
import StudentOrders from './Pages/Profile/StudentOrders'
import AdminDashboard from './Pages/AdminDashboard/AdminDashboard.jsx'
import Users from './Pages/Users/Users'
import Orders from './Pages/Orders/Orders'
import UserProfile from './Components/User/UserProfile.componenet'
import { useSelector } from 'react-redux'
import Chat from './Components/Chat/Chat.component'
import Notification from './Components/Notification/Notification.component'
import Error from './Pages/Error/Error'
import LoginRoute from './Routes/login'


function AppRouter() {
  const userData = useSelector((state => state.userData.userData))

  let isAuthenticated = Object.keys(userData).length !== 0;
  let adminRole=(userData.role==='admin'||userData.role==='writer');
  return (
    <div>

      <BrowserRouter>
        <Routes>
          <Route exact path="/home" element={<Home />} />

          <Route exact path="/" element={
            Object.keys(userData).length == 0 ? <Home /> : <AdminDashboard>
              <Orders />
            </AdminDashboard>
          } />
          <Route exact path="/courses" element={
            <Courses />
          } />


          <Route exact path="/Plagiarism-Check" element={<Plagiarism />} />
          <Route exact path="/Book-Now" element={<BookNow />} />
          <Route exact path="/contact" element={<ContactUs />} />

          <Route exact path="/Profile" element={
            <LoginRoute
              isAuthenticated={isAuthenticated}
            >
              <Profile />
            </LoginRoute>
          } />


          <Route exact path="/NotFound" element={<Error />} />

          {/* <Route exact path="/My-orders" element={<StudentOrders/>}/> */}

          <Route exact path="/My-orders" element={
            <AdminDashboard>
              <LoginRoute
                isAuthenticated={isAuthenticated }
              >
                <Orders searchFor={'Completed'} />
              </LoginRoute>
            </AdminDashboard>
          } />

          <Route exact path="/my-profile" element={
            <AdminDashboard>
              <LoginRoute
                isAuthenticated={isAuthenticated }
              >
                <UserProfile />
              </LoginRoute>

            </AdminDashboard>
          } />

          <Route exact path="/admin" element={
            <AdminDashboard>
              <LoginRoute
                isAuthenticated={isAuthenticated && adminRole}
              >
                <Users />
              </LoginRoute>
            </AdminDashboard>


          } />
          <Route exact path="/Orders-History" element={
            <AdminDashboard>
              <LoginRoute
                isAuthenticated={isAuthenticated && adminRole}
              >
                <Orders searchFor={'Completed'} />
              </LoginRoute>
            </AdminDashboard>

          } />

          <Route path="OrdersChats/:id" element={
            <AdminDashboard>
              <LoginRoute
                isAuthenticated={isAuthenticated && adminRole}
              >
                <Chat />
              </LoginRoute>
            </AdminDashboard>

          } />

          <Route exact path="/order-enquiry" element={
            <AdminDashboard>
              <LoginRoute
                isAuthenticated={isAuthenticated && adminRole}
              >
                <Orders searchFor={'Enquiry'} />
              </LoginRoute>

            </AdminDashboard>
          } />
          <Route exact path="/assigned-orders" element={
            <AdminDashboard>
              <LoginRoute
                isAuthenticated={isAuthenticated && adminRole}
              >
                <Orders searchFor={'In-Progress'} />
              </LoginRoute>
            </AdminDashboard>
          } />


          <Route exact path="/users" element={
            <AdminDashboard>
               <LoginRoute
                isAuthenticated={isAuthenticated && adminRole}
              >
              <Users />
              </LoginRoute>
            </AdminDashboard>

          } />
          <Route exact path="/notification" element={
            <AdminDashboard>
               <LoginRoute
                isAuthenticated={isAuthenticated && adminRole}
              >
              <Notification />
              </LoginRoute>
            </AdminDashboard>

          } />


        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default AppRouter