import axios from "axios";
import { PRE_PROD_API_URL, AUTH_TOKEN, getItem, SESSION_EXPIRE_TIME } from "../constants";
import { dispatch } from "../redux/store";
// import { logout } from "../redux/slices/auth";
import { messageActions } from "../redux/slices/message";

export const getAuthorizationHeader = () => getItem(AUTH_TOKEN);

export const api = axios.create({
    baseURL: PRE_PROD_API_URL,
    headers: {
        Accept: 'application/json, text/plain, */*',
    },
})

api.interceptors.request.use(
    function (config) {
        config.headers.Authorization = "Bearer " + getAuthorizationHeader()
        return config
    }, function (error) {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.code === 'ERR_NETWORK' && new Date() > getItem(SESSION_EXPIRE_TIME)) {
            // dispatch(logout());
            dispatch(messageActions.setMessage({ message: "Session Invalid! Token expired.", messageType: "error" }))
        } else {
            dispatch(messageActions.setMessage({ message: error.response.data.message, messageType: "error" }))
        }
        return Promise.reject(error);
    }
)

export const customAuthApi = axios.create({
    baseURL: PRE_PROD_API_URL,
    headers: {
        Accept: "*/*",
        "content-type": "application/json",
    },
});


// const URL='http://52.192.238.139:8000'
//const URL = 'https://quick2response.com/api'
const URL="https://quick2response.com/api"

export const authSignUp = async (data) => {
    try {
        return await axios.post(`${URL}/signup`, data)
    }
    catch (error) {
        console.log("Error while calling signup api", error);

    }
}

export const authLogin = async (data) => {
    try {
        return await axios.post(`${URL}/login`, data)
    }
    catch (error) {
        console.log("Error while calling login api", error);
        return error.response;
    }
}

export const authVerify = async (data) => {
    try {
        return await axios.post(`${URL}/signup/verify`, data)
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}
export const authUpdate = async (data) => {
    try {
        data.token = getItem('AUTH_TOKEN')
        return await axios.put(`${URL}/update`, data)
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}

export const sendContactForm = async (data) => {
    try {
        return await axios.post(`${URL}/contactForm`, data)
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}

export const authGetUsers = async (data) => {
    try {

        return await axios.post(
            `${URL}/getUsers`,
            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                }
            }
        )


    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}
export const authGetOrders = async (searchFor) => {

    try {
        console.log(`${URL}/getOrders?val=${searchFor}`)
        return await axios.post(
            `${URL}/getOrders?val=${searchFor}`,
            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                },
            }
        )


    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}


export const DownloadFile = async (data) => {
    try {

        return await axios.post(
            `${URL}/getFile`,

            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                },
                data: data,
                // responseType:'blob',

            },
        )
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}


export const placeOrder = async (data) => {
    try {
        data.token = getItem('AUTH_TOKEN')
        return await axios.put(`${URL}/createOrder`, data)
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}
export const enquiryResponse = async (data) => {
    try {

        return await axios.put(
            `${URL}/enquiryResponse`,

            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                },
                data: data,

            },

        )
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }

}
export const UpdateOrder = async (data) => {
    try {

        return await axios.put(
            `${URL}/updateOrders`,

            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                },
                data: data,

            },

        )
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }

}
export const addMessage = async (data) => {
    try {

        return await axios.put(
            `${URL}/addMessage`,
            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                },
                data
            }
        )


    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}
export const getMessage = async (data) => {
    try {

        return await axios.post(
            `${URL}/getMessage`,
            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                },
                data
            }
        )
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}
export const getNotification = async () => {
    try {

        return await axios.post(
            `${URL}/getNotification`,
            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                }

            }
        )
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}
export const readNotification = async (data) => {
    try {

        return await axios.put(
            `${URL}/readNotification`,
            {
                headers: {
                    "Authorization": `Bearer ${getItem("AUTH_TOKEN")}`
                },
                data

            }
        )
    }
    catch (error) {
        console.log("Error while calling signup api", error);
    }
}
export const authApi = api;