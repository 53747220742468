function CardImage({description, image, title}) {
  return (
    
    <div class="flex justify-center m-auto mt-10" style={{height:"450px"}}>
  <div class="rounded-lg shadow-lg bg-white max-w-sm">
    <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
      <img class="rounded-t-lg w-96" src={image} alt=""/>
    </a>
    <div class="p-6 text-center">
      <h6 class="text-gray-900 font-medium mb-2">{title}</h6>
      <p class="text-gray-700 text-base mb-4">
        {description}
      </p>
      {/* <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Button</button> */}
    </div>
  </div>
</div>
  )
}

export default CardImage