import React from "react";
import { useEffect,useState } from "react";
import {useDispatch} from 'react-redux';

import {
    MailOutlined,
    SettingOutlined,
    AppstoreOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import { productActions } from "../../redux/slices/productSlice";
import { useNavigate } from "react-router-dom";
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type
    };
}


const DropDown = ({ TaskList,mobile }) => {
    let navigate = useNavigate();
    const UniDispatch=useDispatch();
    const [TaskHoverList, setTaskHoverList] = useState([]);
    const items = [
        getItem("SERVICES", "sub1", <></>, TaskHoverList)
    ];


    useEffect(() => {
        let arr = [];
        TaskList.map((TaskListItem) => {
            arr.push(getItem(TaskListItem.TName, TaskListItem.TID))


        })
        setTaskHoverList([...arr]);

    }, [TaskList])



    return (

        <Menu
            onClick={(e)=>{
                console.log("click", e.key)
                UniDispatch(productActions.setSelTID(e.key))
                navigate('/Book-Now');
            }}
            style={{

                background: "transparent",
                color: mobile?'black' :"white",
                paddingTop: "9.5px",
                mx: 2,
                display: 'block',
                fontFamily: '"Roboto","Helvetica","Arial","sans-serif"',
                fontWeight: mobile?"400":"700",
                fontSize: "0.875rem"

            }}
            mode="horizontal"
            items={items}
        />
    )
};
export default DropDown;
