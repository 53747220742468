import { useState } from 'react';
import { useEffect } from 'react';
import { authGetOrders } from '../../services/api'
import OrdersList from './OrdersList';

function Orders(props) {
    var searchFor=props.searchFor
    const [OrderData, setOrderData] = useState([])
    const [userList, setUserList] = useState([])
    useEffect(() => {
        (
            async() => {
            let myRes = await authGetOrders(searchFor);
            for (let i=0;i<myRes.data.data.length;i++){
                myRes.data.data[i].id=i+1
            }
            setOrderData(myRes.data.data)
            setUserList(myRes.data.userList)
            console.log(myRes.data.data);  
            // console.log(searchFor)      
        }
        )();
        
    }, [searchFor])

    return (
        <div>
            <OrdersList OrderData={OrderData} userList={userList} />
        </div>
    )
}

export default Orders