import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../Components/Footer/Footer.component'
import Header from '../../Components/Header/Header.component'
import MessageModal from '../../Components/MessageModal/MessageModal.component'
import PriceCalc from '../../Components/PriceCalc/PriceCalc.component'
import { messageActions } from '../../redux/slices/message'

function BookNow() {
    const message = useSelector((state => state.message.message))
    const messageType = useSelector((state => state.message.messageType))
    const dispatch= useDispatch()
    return (
        <div className=''>

            {
                message && <MessageModal
                    status={messageType || 'success'}
                    message={message}
                    setMessage={() => dispatch(messageActions.clearMessage())}
                />
            }
            <Header />
            <div className="h-screen pt-4" > <PriceCalc /></div>
            <Footer />


        </div>
    )
}

export default BookNow