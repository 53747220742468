import React from 'react'
import './Button.style.scss'

function Button({val,onClick,isValid=false,type='Submit'}) {

  return (
    <div>
      <button className={` m-6 button p-2 px-10 border ${isValid && 'disable'}`} type={type} onClick={onClick}>{val}</button>
    </div>
  )
}

export default Button