import Button from "../../Components/Button/Button.component"
import Input from "../../Components/Input/Input.component"
import Label from "../../Components/Label/Label.component"
import { useFormik } from 'formik'
import { ContactUsSchema } from "../../validationSchema";
import Error from "../../Components/Error/Error.component";
import { Box, TextField } from '@mui/material';

import { sendContactForm } from "../../services/api";

function ContactForm() {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      contact: '',
      msg: '',
      upload:''
    },
    validationSchema: ContactUsSchema,
    onSubmit: values => {
      console.log(values);
      sendContactForm(values);

    },
  });


  return (
    <Box sx={{ flex: 2 }}>
      <div className='border w-screen'
        style={{
          display: "block",
          margin: "auto",
          marginTop: "30px",
          boxShadow: "0px 0px 10px 3px rgba(153,153,153,0.7)",
          maxWidth:"680px"
        }}>
        <form onSubmit={formik.handleSubmit}>
          <p className=" p-5">If you want our exective to contact you and address your queries, <br></br>kindly fill the below information and proceed:</p>

          <div className="flex flex-wrap">
            <div className=" ">
              <Label val='Name' />
              <Input placeholder={'Enter your Name'}
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {
                formik.touched.name &&
                <Error>{formik.errors.name}</Error>

              }
            </div>
            <div>
              <Label val='Email' />
              <Input
                placeholder={'Enter your Email'}
                name='email'
                type="email"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {
                formik.touched.email &&
                <Error>{formik.errors.email}</Error>
              }
            </div>
          </div>

          <div className=" flex flex-wrap">
            <div>
              <Label val='Contact' />
              <Input placeholder={'Enter your Mobile No.'}
                type="number"
                name="contact"
                onBlur={formik.handleBlur}
                value={formik.values.contact}
                onChange={formik.handleChange}
              />
              {
                formik.touched.contact &&
                <Error>{formik.errors.contact}</Error>

              }
            </div>
            <div>
              <Label val='Upload Assessment document' />
              <Input
                name='upload'
                type="file"
              
                onChange={(e) => {
                  const file = e.target.files[0]
                  let reader = new FileReader()
                  reader.readAsDataURL(file)
                  reader.onload = () => {
                    formik.setFieldValue('upload',{data:reader.result,name:file.name,type:file.type});
                    
                  }
  }} />
              {
                formik.touched.upload &&
                <Error>{formik.errors.upload}</Error>

              }
            </div>
          </div>
          <div className='flex place-items-end '>
            <div>
              <Label val='Description' />
              <Input placeholder={'Enter your message'} TextField={true}
                name='msg'
                value={formik.values.msg}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {
                formik.touched.msg &&
                <Error>{formik.errors.msg}</Error>
              }
               <div>
              <Button type="Submit" val="Submit" isValid={!formik.dirty || !formik.isValid} />
              
            </div>
            </div>
           
          </div>
        </form>

      </div>
    </Box>
  )
}

export default ContactForm