import React from 'react'
import Footer from '../../Components/Footer/Footer.component'
import Header from '../../Components/Header/Header.component'

function Plagiarism() {
    return (
        <div>
            <Header />
            <iframe src="https://papersowl.com/free-plagiarism-checker" height="100%" width="100%" title="Iframe Example"></iframe>
            <Footer/>

        </div>
    )
}

export default Plagiarism