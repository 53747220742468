import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import logo from '../../assets/img/mylogo.png';
import './Header.style.scss';
import ModalContainer from '../Modal/ModalContainer.component';
import Navbar from './Navbar.component';
import MobileNav from './MobileNav.component';
function Header({admin}) {
  return (<>
    <div className={` w-full max-h-fit h-auto text-xl ${admin && ' w-11/12 bg-transparent'}`} style={{position:"fixed", zIndex:"10",marginTop:"-100px",}}>
      <div className=' w-full text-center  p-1 bg-slate-900 text-gray-50'>
        Contact us on +91-94191-06996
      </div>
    <Navbar admin={admin} ></Navbar>
     </div>
    </>

  )
}

export default Header