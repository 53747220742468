import * as yup from 'yup';

const required="This is required field";
const email="Invalid Email";
const phoneErrMsg = "Phone Number should have only 10 digits"
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const OTPRegExp = /^[0-9]{6,6}$/
const OTPErrMsg="OTP must contain only 6 digits"
const gstRegex = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'
const gstErrorMsg = 'Invalid GST'
const passRegExp =/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
const passErrMsg="Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"

export const loginSchema = yup.object().shape({
  email: yup.string().trim().email(email).required(required),
  password: yup.string().trim().min(3).max(25).required(required),
});

export const signUpSchema = yup.object().shape({
    name:yup.string().trim().min(3).max(25).required(required),
    email: yup.string().trim().email(email).required(required),
    password: yup
    .string()
    .required(required)
    .matches(
      passRegExp,
      passErrMsg
    ),
    contact:yup.string().trim().required(required).matches(phoneRegExp,phoneErrMsg),
    OTP:yup.string().trim().matches(OTPRegExp,OTPErrMsg),
    // gst:yup.string().trim().required(required).matches(gstRegex,gstErrorMsg),
    // upload:yup.mixed().test('required', required, (file) =>{return file && file.length})
  });
  
  export const ContactUsSchema = yup.object().shape({
    name:yup.string().trim().min(3).max(25).required(required),
    email: yup.string().trim().email(email).required(required),
    contact:yup.string().trim().required(required).matches(phoneRegExp,phoneErrMsg),
    // upload:yup.mixed().test((file) =>{return file && file.length}),
    msg:yup.string().trim().min(3).max(250).required(required),
  });

  export const updateSchema = yup.object().shape({
    fname:yup.string().trim().min(3).max(25).required(required),
    lname:yup.string().trim().min(3).max(25),
    email: yup.string().trim().email(email).required(required),
    location:yup.string().trim().min(3).max(25),
    course:yup.string().trim().min(3).max(25),
    contact:yup.string().trim().required(required).matches(phoneRegExp,phoneErrMsg),
    
  });
