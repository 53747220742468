import "./Modal.styles.scss"
import closeIcon from "../../assets/Icons/closeIcon.png"

import Modal from "react-modal"

const customStyles = {
  content: {
    borderRadius: "10px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "#00000080",
    zIndex: "100000",
  },
}

const ModalComponent = ({
  children,
  isOpenHandler,
  modalType,
  closeModal,
  ...otherProps
}) => {
  Modal.setAppElement("body")

  return (
    <Modal
      isOpen={isOpenHandler}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      {...otherProps}
    >
      {closeIcon && (
        <div className="close-icon icon-stationary">
          <object
          aria-label="closeIcon"
            onClick={(_event) => closeModal(modalType)}
            className="icon"
            data={closeIcon}
            type=""
          ></object>
        </div>
      )}
      {children}
    </Modal>
  )
}

export default ModalComponent
