import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {styled} from '@mui/material'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';



const CustomDate=styled(DesktopDateTimePicker)`
width:285px;
margin: 20px 24px;


`
export default function ResponsiveDateTimePickers({value,setValue}) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     
      
        <CustomDate
         label="Deadline"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
       
    
    </LocalizationProvider>
  );
}