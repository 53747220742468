import React from 'react'
import CardImage from '../Cards/CardImage.component'
import img1 from './../../assets/img/faculty/1.jpg'
import img2 from './../../assets/img/faculty/2.jpg'
import img3 from './../../assets/img/faculty/3.jpg'
function ServicesOver({ children }) {
  return (
    <>


      <div id="faculty-sec" className=' bg-slate-700 opacity-90 p-5 text-white' style={{ marginBottom: '-243px', lineHeight: '40px' }} >
        {children}
        <div className="p-5 w-full" >
          <div className="text-center ">
            <div className="w-full">
              <h1 style={{ marginBottom: '20px', fontSize: "30px", fontWeight: "650" }}>OUR SERVICES </h1>
              <p style={{ marginBottom: '20px', fontSize: "20px" }}>
                Our team of qualified professionals work with dedication to serve all your education needs.
              </p>
            </div>

          </div>
          <div className=' flex justify-between flex-wrap  '>

            <CardImage
              description={'Our experts use variety of instructional methods, techniques and educational resources in different subjects of technical and non-technical courses thereby helping students to accelerate their learning progress.'}
              title={'ONLINE COACHING/ MENTORING'}
              image={img1}
            />
            <CardImage
              description={'Our experts provide dedicated assistance by providing educational resources, instructions, assitance in data collection for your thesis, research, programming projects etc.'}
              title={'HELP WITH RESEARCH PROJECTS/ THESIS'}
              image={img2}
            />
            <CardImage
              description={'We provide advice and assistance to students in the development of their educational plans, choice of appropriate courses, and choice of college or technical school. '}
              title={'EDUCATION COUNSELLING'}
              image={img3} />
          </div>


          {/* <div className="flex text-center" >
           
               
                 <div className="" data-scroll-reveal="enter from the bottom after 0.4s">
                     <div className="faculty-div">
                     <div className="block m-auto w-fit">
                     <img src={img1}  className="" /></div>
                   <h3 style={{fontFamily:'"Brush Script MT", cursive', marginBottom:'10px'}}>ONLINE COACHING/ MENTORING</h3>
                 <hr />
                         
                   <p >Our experts use
                    variety of instructional methods, techniques and educational resources in different
                    subjects of technical and non-technical courses thereby helping students to accelerate their learning progress.
                       
                   </p>
                </div>
                   </div>
                 <div className="col-lg-4  col-md-4 col-sm-4" data-scroll-reveal="enter from the bottom after 0.5s">
                     <div className="faculty-div">
                     <div className="block m-auto w-fit">
                     <img src={img2}  className="img-rounded" /> </div>
                   <h3 style={{fontFamily:'"Brush Script MT", cursive', marginBottom:'10px'}}>HELP WITH RESEARCH PROJECTS/ THESIS</h3>
                 <hr />
                        
                   <p >
                       Our experts provide dedicated assistance by providing educational resources, 
                       instructions, assitance in data collection for your thesis, research, programming projects etc.
                       
                   </p>
                </div>
                   </div>
               <div className="col-lg-4  col-md-4 col-sm-4" data-scroll-reveal="enter from the bottom after 0.6s">
                     <div className="faculty-div">
                     <div className="block m-auto w-fit">
                     <img src={img3} className="img-rounded" /> </div>
                   <h3 style={{fontFamily:'"Brush Script MT", cursive', marginBottom:'10px'}}>EDUCATION COUNSELLING</h3>
                 <hr />
                         
                   <p >
                       We provide advice and assistance to students in the 
                       development of their educational plans, choice of appropriate courses, and choice of college or technical school. 
                       
                   </p>
                </div>
                   </div>
                 
               </div> */}
        </div>
      </div>

    </>
  )
}

export default ServicesOver