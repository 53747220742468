import Button from "../../Components/Button/Button.component"
import Input from "../../Components/Input/Input.component"
import Label from "../../Components/Label/Label.component"
import { useFormik } from 'formik'
import { signUpSchema } from "../../validationSchema";
import Error from "../../Components/Error/Error.component";
import { authSignUp, authVerify } from '../../services/api';
import { useState } from "react";
import { setItem } from "../../constants";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../redux/slices/userData";


function SignUp({openModal}) {
  const dispatch=useDispatch();
  const [OTP, setOTP] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      contact: '',
      OTP: ''

    },
    validationSchema: signUpSchema,
    onSubmit: async values => {
      console.log(values);
      if (OTP == false) {
        let  myRes = await authSignUp(values);
        console.log(myRes)
        setItem('AUTH_TOKEN',myRes.data.token)

        // if (myRes.status == 200){
        //   openModal('Otp sent successfully', 'success')
        //  }
        // else
        //   openModal(myRes.data.message, 'error')
        setOTP(true);
        
      }

      else {

        let myRes = await authVerify(values);
        console.log(myRes);
        dispatch(userDataActions.setUserData(myRes.data.data));
        if (myRes.status == 200)
          openModal(myRes.data.message, 'success')
        else
          openModal(myRes.data.message, 'error')
      }

    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Label val='Name' />
      <Input placeholder={'Enter your Name'}
        name="name"
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />
      {
        formik.touched.name &&
        <Error>{formik.errors.name}</Error>

      }
      <Label val='Company Email' />
      <Input
        placeholder={'Enter your Email'}
        name='email'
        type="email"
        onBlur={formik.handleBlur}
        value={formik.values.email}
        onChange={formik.handleChange}
      />
      {
        formik.touched.email &&
        <Error>{formik.errors.email}</Error>

      }
      <Label val='Password' />
      <Input
        placeholder={'Enter your Password'}
        name='password'
        type="password"
        onBlur={formik.handleBlur}
        value={formik.values.password}
        onChange={formik.handleChange}
      />
      {
        formik.touched.password &&
        <Error>{formik.errors.password}</Error>

      }
      <Label val='Contact' />
      <Input placeholder={'Enter your Mobile No.'}
        type="number"
        name="contact"
        onBlur={formik.handleBlur}
        value={formik.values.contact}
        onChange={formik.handleChange}
      />
      {
        formik.touched.contact &&
        <Error>{formik.errors.contact}</Error>

      }



      {OTP &&
        <>
          <Label val='OTP' />
          <Input placeholder={'Enter OTP sent on email'}
            type="number"
            name="OTP"
            onBlur={formik.handleBlur}
            value={formik.values.OTP}
            onChange={formik.handleChange}
          />
          {
            formik.touched.OTP &&
            <Error>{formik.errors.OTP}</Error>

          }
        </>

      }

      <Button val={OTP ? "Register" : "Send OTP"} isValid={!formik.dirty || !formik.isValid} />


    </form>
  )
}

export default SignUp