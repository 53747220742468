import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import useHttp from "../../utils/hooks/useHttp";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { productActions } from "../../redux/slices/productSlice";
import {cartActions} from "../../redux/slices/cartSlice";
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type
    };
}
const DropDownNavCourses = ({mobile}) => {
    let navigate = useNavigate();
    const UniDispatch = useDispatch();
    const [SubList, setSubList] = useState([]);
    const [CatList, setCatList] = useState([]);
    const [TaskHoverList, setTaskHoverList] = useState([]);
    const {sendRequest} = useHttp();
    async function fetchData() {
        let x = "common"
        await sendRequest({
            method: "GET",
            url: `https://quick2response.com/api/getCategory?val=${x}`

        }, (data) => {  
               
            setSubList([...data.sub])
            setCatList([...data.cat])
        }, "")
    }


    useEffect(() => {
        let arr = [];
        CatList.map((catListItem) => {
            let arr1 = []
            SubList.filter((subListItem) => subListItem.CID === catListItem.CID).map((subListItem) => {
            arr1.push(getItem(subListItem.SNAME, subListItem.SID,<></>,null,null))})
            arr.push(getItem(catListItem.CNAME, catListItem.CID, null, arr1))})
        setTaskHoverList([...arr]);  
    }, [CatList, SubList])
    
    const items = [
        getItem("COURSES", "sub2", null, TaskHoverList)
    ];
    useEffect(() => {
        fetchData();
    }, [])

    return (
<div style={{ minWidth:"105px"}}>
<Menu
  
  onClick={(e)=>{
      UniDispatch(productActions.setSelSID(e.key))
      UniDispatch(cartActions.setItem({key:'subject',value:[e.key,'curSubName']}));
      navigate('/Book-Now');
  }}
      style={{

          background: "transparent",
          color: mobile?'black' :'white',
          paddingTop: "9.5px",
          mx: 2,
          display: 'block',
          fontFamily: '"Roboto","Helvetica","Arial","sans-serif"',
          fontWeight: mobile?"400":"700",
          fontSize: "0.875rem"

      }}
      mode="horizontal"
      items={items}
  />
</div>
      
    )
};
export default DropDownNavCourses;
