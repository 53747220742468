import "./MessageModal.styles.scss";

import { ReactComponent as DoneIcon } from "../../assets/Icons/done_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close_icon.svg";
import ModalComponent from "../Modal/Modal.component";
import { useDispatch } from "react-redux";
import { messageActions } from "../../redux/slices/message";

const MessageModal = ({ status, message, setMessage }) => {
  const dispatch = useDispatch()

  return (
    <ModalComponent
      closeIcon={true}
      isOpenHandler={message ? true : false}
      closeModal={() => {
        setMessage("");
        dispatch(messageActions.clearMessage())
      }
      }
    >
      <div className="add-modal-row display-center">
        <div className={`icon-box ${status === "success" ? "bg-green" : "bg-red"}`}>
          {status === "success" ? <DoneIcon fill="white" /> : <CloseIcon fill="white" />}
        </div>
      </div>
      <div className="display-center">
        <h5>{status === "success" ? "Success!" : "Error!"}</h5>
      </div>
      <div className="display-center">
        <h6>{message}</h6>
      </div>
    </ModalComponent>
  )
}

export default MessageModal;