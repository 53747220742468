import { createSlice } from "@reduxjs/toolkit";
import { setItem } from "../../constants";
import io from 'socket.io-client';

export const userDataSlice = createSlice({
    name: "userData",
    initialState: { userData: {}, writerData: {}, socket: '', notifications: [] },
    reducers: {
        setUserData: (state, { payload }) => {
            state.userData = payload
            const serverUrl = 'https://quick2response.com/socket';
            state.socket = io(serverUrl);
        },
        setNotification: (state, { payload }) => {
            state.notifications = payload
        },
        setWriterData: (state, { payload }) => {
            state.writerData = payload
        },
        logout: (state) => {
            state.userData = {}
            setItem('AUTH_TOKEN', null)
            state.socket.disconnect();
            state.socket.off();
        }
    }
})
export const userDataActions = userDataSlice.actions;
export default userDataSlice.reducer
