import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { messageActions } from '../../redux/slices/message';
import { dispatch } from '../../redux/store';
import UserUpdateForm from '../../Components/User/UserUpdateForm.component';
import SpringModal from '../../Components/Modal/ModalContainer.component';
import { DownloadFile, UpdateOrder } from '../../services/api';
//import fileDownload from 'js-file-download'
import { Autocomplete, TextField, InputLabel, Input } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { green } from '@mui/material/colors';
import MultipleSelect from '../../Components/MultipleSelect/MultipleSelect.component';
import { useNavigate, useParams } from 'react-router-dom';
import { userDataActions } from '../../redux/slices/userData';
import { enquiryResponse } from '../../services/api';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';



export default function OrdersList({ OrderData, userList }) {

    const userData = useSelector((state => state.userData.userData))
    const navigate = useNavigate()
    function updateReply(e, id) {
        console.log(e)
        console.log(id)

        if (e.target.textContent == 'Accept') {

            if (OrderData[id - 1].Accepted.indexOf(userData.email) >= 0) {
                console.log(OrderData[id - 1].Accepted.indexOf(userData.email))
                console.log("already accepted");
            }
            else {
                OrderData[id - 1].Accepted.push(userData.email);
                if (OrderData[id - 1].Rejected.indexOf(userData.email) >= 0) {
                    OrderData[id - 1].Rejected.splice(OrderData[id - 1].Rejected.indexOf(userData.email), 1);
                }
            }
        }
        else if (e.target.textContent == 'Reject') {
            if (OrderData[id - 1].Rejected.indexOf(userData.email) >= 0) {
                console.log("already rejected");
            }
            else {
                OrderData[id - 1].Rejected.push(userData.email);
                if (OrderData[id - 1].Accepted.indexOf(userData.email) >= 0) {
                    OrderData[id - 1].Accepted.splice(OrderData[id - 1].Rejected.indexOf(userData.email), 1);
                }
            }
        }
        console.log(OrderData);
    }


    const [CurrUserData, setCurrUserData] = React.useState([])
    function openModal(msg, mtype) {
        dispatch(messageActions.setMessage({ message: msg, messageType: mtype }))
    }

    let CurrData = [...userList]
    let WriterList = []
    let WriterData = []

    for (let i = 0; i < CurrData.length; i++) {
        if (CurrData[i].role == 'writer') {
            WriterList.push(CurrData[i].name + '-' + CurrData[i].email)
            WriterData.push(CurrData[i])
        }
    }
    dispatch(userDataActions.setWriterData(WriterData))
    //userList=[...WriterList]       


    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: "userID",
            headerName: "userID",
            width: 270,
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    const index = userList.findIndex((element) => element._id === OrderData[params.id - 1].userID);
                    setCurrUserData(userList[index])
                    console.log(userList[index])
                };

                return <div onClick={onClick}
                    style={{ background: "skyblue" }}
                >       <SpringModal
                    text={`${OrderData[params.id - 1].userID}`}
                    sx={{
                        padding: "0px !important",
                        color: "blue"
                    }}>
                        <UserUpdateForm userData={CurrUserData} openModal={openModal}></UserUpdateForm>

                    </SpringModal></div>;
            }
        },
        {
            field: "_id",
            headerName: "Order ID",
            width: 215,
            renderCell: (params) => {
                return (

                    <div className=' text-blue-400 cursor-pointer hover:underline'
                        onClick={() => navigate('/OrdersChats/' + OrderData[params.id - 1]._id)}
                    >
                        {OrderData[params.id - 1]._id}
                    </div>

                )
            }

        },

        {
            field: 'createdAt',
            headerName: 'Order Date',
            width: 130,

        },
        {
            field: "Status",
            headerName: "Curr Status",
            width: 190,
            sortable: false,
            renderCell: (params) => {
                const StatusList = ['Pending', 'Completed', 'Cancelled', 'In-Progress']
                return (

                    <Autocomplete
                        onChange={(e) => {
                            OrderData[params.id - 1].Status = e.target.textContent
                        }}
                        disablePortal
                        id="combo-box-demo"
                        value={OrderData[params.id - 1].Status}
                        options={StatusList}
                        sx={{
                            width: '100%',
                            border: 'none'

                        }}
                        renderInput={(params) => <TextField {...params} label="" />}
                    />

                )
            }
        },
        {
            field: "enq",
            headerName: "Enquiry To",
            width: 300,
            sortable: false,
            renderCell: (params) => {

                return (
                    <MultipleSelect names={WriterList} currOrderList={OrderData[params.id - 1]} />

                )
            }
        },
        {
            field: "role",
            headerName: "Assigned",
            width: 190,
            sortable: false,
            renderCell: (params) => {
                let val = [...WriterList].filter((value) => {
                    if (value.split('-')[1] == OrderData[params.id - 1].Assigned) {
                        return value
                    }

                })
                if (OrderData[params.id - 1].Assigned === undefined || val.length === 0) {
                    val = ''
                }


                return (<>
                    {
                        val.length !== 0 ?
                            <Autocomplete
                                onChange={(e) => {
                                    OrderData[params.id - 1].Assigned = e.target.textContent.split('-')[1]
                                }}
                                disablePortal
                                id="combo-box-demo"
                                value={val}
                                options={WriterList}
                                sx={{
                                    width: '100%',
                                    border: 'none'

                                }}
                                renderInput={(params) => <TextField {...params} label="" />}
                            />

                            :
                            <Autocomplete
                                onChange={(e) => {
                                    OrderData[params.id - 1].Assigned = e.target.textContent.split('-')[1]
                                }}
                                disablePortal
                                id="combo-box-demo"
                                //value={val}
                                options={WriterList}
                                sx={{
                                    width: '100%',
                                    border: 'none'

                                }}
                                renderInput={(params) => <TextField {...params} label="" />}
                            />

                    }
                </>

                )
            }
        },
        {
            field: 'TID',
            headerName: 'TaskName',
            width: 130,
        },

        {
            field: 'SubID',
            headerName: 'SubID',
            width: 130,
        },

        {
            field: 'Page',
            headerName: 'Page',
            width: 100,
        },
        {
            field: 'Price',
            headerName: 'Price',
            width: 100,
            editable: true
        },
        {
            field: 'Deadline',
            headerName: 'Deadline',
            width: 200,
        },

        {
            field: "File",
            headerName: "File",
            width: 100,
            sortable: false,
            renderCell: (params) => {
                const onClick = async () => {

                    let data = {
                        File: JSON.parse(OrderData[params.id - 1].File),
                        userID: OrderData[params.id - 1]._id
                    }
                    let myRes = await DownloadFile(data);
                    console.log(JSON.parse(OrderData[params.id - 1].File).type)
                    console.log(myRes.data.data)
                    let blob = myRes.data.data
                    const base64 = blob;
                    const link = document.createElement('a');
                    link.href = `data:${JSON.parse(OrderData[params.id - 1].File).type};base64,` + base64;
                    link.download = JSON.parse(OrderData[params.id - 1].File).fileName;;
                    link.click();

                }
                return (
                    <button onClick={onClick}>
                        {`${JSON.parse(OrderData[params.id - 1].File).fileName}`}
                    </button>
                )
            }
        },
        {
            field: 'Desc',
            headerName: 'Order Description',
            width: 280,
        },



        {
            field: "Update",
            headerName: "Update",
            sortable: false,
            renderCell: (params) => {
                const onClick = async (e) => {
                    //setCurrUserData(OrderData[params.id - 1])
                    //console.log(OrderData[params.id-1])
                    let myRes = await UpdateOrder(OrderData[params.id - 1])
                    console.log(myRes);
                };

                return <Button onClick={onClick}>
                    Update
                </Button>;
            }
        },

    ];
    const NonAdminColumns = [
        { field: 'id', headerName: 'ID', width: 70 },

        {
            field: "_id",
            headerName: "Order ID",
            width: 150,

        },

        {
            field: 'createdAt',
            headerName: 'Order Date',
            width: 130,
        },
        {
            field: "Status",
            headerName: "Curr Status",
            renderCell: (params) => {
                let color = 'bg-yellow-500'
                if (OrderData[params.id - 1].Status === 'Completed')
                    color = 'bg-green-500'
                else if (OrderData[params.id - 1].Status === 'Cancelled')
                    color = 'bg-red-500'
                else if (OrderData[params.id - 1].Status === 'In-Progress')
                    color = 'bg-blue-500'

                return (
                    <li className="flex items-center py-3">
                        <span className="ml-auto"><span
                            className={`${color} py-1 px-2 rounded text-white text-sm`}>{`${OrderData[params.id - 1].Status}`}</span></span>
                    </li>
                    // <p style={{background:`${color}`, color:'white'}}>


                    // </p>
                )
            }

        },

        {
            field: 'TID',
            headerName: 'TaskName',
            width: 130,
        },

        {
            field: 'SubID',
            headerName: 'SubID',
            width: 130,
        },

        {
            field: 'Page',
            headerName: 'Page',
            width: 100,
        },
        {
            field: 'Price',
            headerName: 'Price',
            width: 100,

        },
        {
            field: 'Deadline',
            headerName: 'Deadline',
            width: 200,
        },

        {
            field: "File",
            headerName: "File",
            width: 270,
            sortable: false,
            renderCell: (params) => {
                const onClick = async () => {

       
                    let data = {
                        File: JSON.parse(OrderData[params.id - 1].File),
                        userID: OrderData[params.id - 1]._id
                    }
                    let myRes = await DownloadFile(data);
                    console.log(JSON.parse(OrderData[params.id - 1].File).type)
                    console.log(myRes.data.data)
                    const base64 = myRes.data.data;  // …
                    const link = document.createElement('a');
                    link.href = `data:${JSON.parse(OrderData[params.id - 1].File).type};base64,` + base64;
                    link.download = JSON.parse(OrderData[params.id - 1].File).fileName;;
                    link.click();

                }
                return (
                    <button onClick={onClick}>
                        {`${JSON.parse(OrderData[params.id - 1].File).fileName}`}

                    </button>
                )
            }
        },
        {
            field: 'Desc',
            headerName: 'Order Description',
            width: 280,
        },
    ];


    const writerColumn = [
        { field: 'id', headerName: 'ID', width: 70 },

        // {
        //     field: "_id",
        //     headerName: "Order ID",
        //     width: 150,

        // },
        {
            field: "_id",
            headerName: "Order ID",
            width: 215,
            renderCell: (params) => {
                return (

                    <div className=' text-blue-400 cursor-pointer hover:underline'
                        onClick={() => navigate('/OrdersChats/' + OrderData[params.id - 1]._id)}
                    >
                        {OrderData[params.id - 1]._id}
                    </div>

                )
            }

        },
        {
            field: 'createdAt',
            headerName: 'Order Date',
            width: 130,
        },
        {
            field: "Status",
            headerName: "Curr Status",
            renderCell: (params) => {
                let color = 'bg-yellow-500'
                if (OrderData[params.id - 1].Status === 'Completed')
                    color = 'bg-green-500'
                else if (OrderData[params.id - 1].Status === 'Cancelled')
                    color = 'bg-red-500'
                else if (OrderData[params.id - 1].Status === 'In-Progress')
                    color = 'bg-blue-500'

                return (
                    <li className="flex items-center py-3">
                        <span className="ml-auto"><span
                            className={`${color} py-1 px-2 rounded text-white text-sm`}>{`${OrderData[params.id - 1].Status}`}</span></span>
                    </li>
                    // <p style={{background:`${color}`, color:'white'}}>


                    // </p>
                )
            }

        },


        {
            field: 'TID',
            headerName: 'TaskName',
            width: 130,
        },

        {
            field: 'SubID',
            headerName: 'SubID',
            width: 130,
        },

        {
            field: 'Page',
            headerName: 'Page',
            width: 100,
        },
        ,
        {
            field: 'Deadline',
            headerName: 'Deadline',
            width: 200,
        },

        {
            field: "File",
            headerName: "File",
            width: 170,
            sortable: false,
            renderCell: (params) => {
                const onClick = async () => {

                    let data = {
                        File: JSON.parse(OrderData[params.id - 1].File),
                        userID: OrderData[params.id - 1]._id
                    }
                    let myRes = await DownloadFile(data);


                    console.log(JSON.parse(OrderData[params.id - 1].File).type)
                    console.log(myRes)
                    let blob = myRes.data
                    //fileDownload(myRes.data,JSON.parse(OrderData[params.id - 1].File).fileName)
                    const blobUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = JSON.parse(OrderData[params.id - 1].File).fileName;
                    //document.body.appendChild(link);
                    link.click();

                }
                return (
                    <button onClick={onClick}>
                        {`${JSON.parse(OrderData[params.id - 1].File).fileName}`}

                    </button>
                )
            }
        },
        {
            field: 'Desc',
            headerName: 'Order Description',
            width: 280,
        },

        {
            field: "AcceptReject",
            headerName: "Accept/Reject",
            width: 190,
            sortable: false,
            renderCell: (params) => {
                const proposalList=['Accept', 'Reject']
                return (
        
                            <Autocomplete
                      onChange={(e) => {
                        OrderData[params.id-1].Accepted=OrderData[params.id-1].Accepted.push(userData.email)
                      }}
                      disablePortal
                      id="combo-box-demo" 
                      value={OrderData[params.id - 1].R}
                      options={proposalList}
                      sx={{
                        width: '100%',
                        border:'none'
                          
                      }}
                      renderInput={(params) => <TextField {...params} label="" />} 
                      />
        
                )
            }
        },

        {
            field: "Update",
            headerName: "Update",
            sortable: false,
            renderCell: (params) => {
                const onClick = async (e) => {


                    //setCurrUserData(OrderData[params.id - 1])
                    //console.log(OrderData[params.id-1])
                    let myRes = await enquiryResponse(OrderData[params.id - 1])
                    console.log(myRes);
                };

                return <Button onClick={onClick}

                >
                    Update
                </Button>;
            }
        },
    ];

    function openModal(msg, mtype) {
        dispatch(messageActions.setMessage({ message: msg, messageType: mtype }))
    }
    // let curColumn= NonAdminColumns
    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={OrderData}
                columns={userData?.role == 'admin' ? columns : userData.role == 'writer' ? writerColumn : NonAdminColumns}
                pageSize={5}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />

        </div>
    );
}