import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, Button } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import SpringModal from '../Modal/ModalContainer.component';
import Login from '../../Pages/Login/Login.component';
import img1 from '../../../src/assets/img/emptyCart.jpg';
import { cartActions } from '../../redux/slices/cartSlice';
import { placeOrder } from '../../services/api';
import { messageActions } from '../../redux/slices/message';
import MessageModal from '../MessageModal/MessageModal.component';

function Cart() {

  const userData = useSelector((state => state.userData.userData))
  var cartTotal = 0;

  const cartList = useSelector((state => state.cartItems.fullCartItems));
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  const myDispatch = useDispatch();
  const message = useSelector((state => state.message.message))
  const messageType = useSelector((state => state.message.messageType))
  function openModal(msg, mtype) {
    console.log(msg,mtype);
    myDispatch(messageActions.setMessage({ message: msg, messageType: mtype }))
  }
  async function orderHandler() {
    console.log('order Handler');
    let myRes = await placeOrder({ data: cartList });
    console.log(myRes);
    if (myRes.status == 200)
      openModal(myRes.data.message, 'success')

    else
      openModal(myRes.data.message, 'error')

    myDispatch(cartActions.clearCart())
  }

  return (<>
    {
      message && <MessageModal
        status={messageType || 'success'}
        message={message}
        setMessage={() => myDispatch(messageActions.clearMessage())}
      />
    }
    <div className=' p-10'>

      <Box sx={{ flexGrow: 1, }}>
        <Demo sx={{ flexGrow: 1, padding: "20px 30px" }}>

          {
            (cartList).length == 0 ? <img src={img1} /> :
              <div style={{ maxHeight: 502, overflowY: "scroll" }}>
                {
                  cartList.map((item, idx) => {
                    { cartTotal = cartTotal + (Number(item.price)) }
                    return (<List> <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={

                          () => {
                            console.log(idx);
                            myDispatch(cartActions.deleteItem(idx))
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.task}

                        secondary={item.subject[1]
                          + " $" + item.price.toPrecision(4) + "/- AUD"
                        }
                      />
                      {/* <div>{" $"+item.price.toPrecision(4)+"/- AUD"}</div> */}
                    </ListItem></List>)
                  })
                }


              </div>

          }
          {(cartList).length != 0 &&

            <div className=' block m-auto w-fit'>
              <p className=' text-2xl  text-center my-5'>
                Cart Total: ${cartTotal.toPrecision(4) + "/- AUD"}
              </p>
              {Object.keys(userData).length !== 0
                ? <Button type="Submit" val="Complete Orders" onClick={orderHandler} style={{
                  backgroundColor: "#2596be",
                  color: "white"
                }}>
                  Complete Order
                </Button>
                : <Button type="Submit" val="Complete Order" style={{
                  backgroundColor: "#2596be",
                  color: "white"
                }}><SpringModal
                  text="Login to complete order"
                  style={{
                    padding: "0px !important"
                  }}>
                    <Login></Login>
                  </SpringModal></Button>}

            </div>}
        </Demo>


      </Box></div>
  </>
  )
}

export default Cart