import AppRouter from './AppRouter';
import './App.scss';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import store from './redux/store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <AppRouter />
        <div className='tawk-to'>
          <TawkMessengerReact
            propertyId="63c97cf6c2f1ac1e202e95e5"
            widgetId="1gn5h0acs" />
        </div>

      </div>
    </Provider>
  );
}

export default App;

