import React from 'react'
import ContactForm from '../../Components/ContactForm/ContactForm';
import Header from '../../Components/Header/Header.component';
import ContactDesc from '../../Components/ContactForm/ContactDesc.component';
import Footer from '../../Components/Footer/Footer.component';
function ContactUs() {
  return (
    <div>

      <Header />
      <div className=' flex w-screen flex-wrap' style={{marginBottom:"-193px"}} >
        <ContactDesc />
        <ContactForm />
      </div>
      <Footer/>




    </div>
  )
}

export default ContactUs