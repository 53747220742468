import React from 'react'
import Footer from '../../Components/Footer/Footer.component'
import Header from '../../Components/Header/Header.component'

function Courses() {
    return (
        <div>
            <Header />
            <Footer/>

        </div>
    )
}

export default Courses