import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../assets/img/mylogo.png';
import SpringModal from '../Modal/ModalContainer.component';
import Login from '../../Pages/Login/Login.component';
import { useNavigate } from "react-router-dom";
import DropDown from './DropDownNavServices.component';
import useHttp from '../../utils/hooks/useHttp';
import DropDownNavCourses from './DropDownNavCourses.component';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userDataActions } from '../../redux/slices/userData';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { cartActions } from '../../redux/slices/cartSlice';
import Cart from './Cart';

const pages = [
    'Home',
    'Services',
    'Courses',
    'Book-Now',
    'Plagiarism-Check',
    'Contact',
];
const settings = ['Profile', 'My-Orders', 'Admin', 'Logout'];

function Navbar({admin}) {
    const userData = useSelector((state => state.userData.userData))
    const cartList=useSelector((state => state.cartItems.fullCartItems))
    const UniDispatch = useDispatch();

    let navigate = useNavigate();
    const [anchorElNav,
        setAnchorElNav] = React.useState(null);
    const [anchorElUser,
        setAnchorElUser] = React.useState(null);

    const [Active,
        setActive] = useState(pages.indexOf(window.location.pathname.replace('/', '')))
    const [TaskList,
        setTaskList] = useState([]);
    const { sendRequest } = useHttp();



    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (page) => {
        if (page && page !== "Services") {
            console.log(page)
            page = page.replace(' ', '')
            setActive(pages.indexOf(page))
            navigate(`/${page}`);
        }
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = (e) => {
        console.log(e.target.innerText);

        if (e.target.innerText == 'Logout') {
            UniDispatch(userDataActions.logout());
            navigate('/home')
        }
        else {
            navigate(`/${e.target.innerText.replace(' ', '-')
                }`)
        }

        setAnchorElUser(null);
    };

    const getTask = async () => {
        await sendRequest({
            method: "GET",
            url: `https://quick2response.com/api/getTask`
        }, (data) => {
            data = data.data;

            var i;
            for (i = 0; i < data.length; i++) {
                data[i].label = data[i]['TName'];
            }
            setTaskList([...data])


        }, "")
    };
    useEffect(() => {
        getTask()

    }, [])

    return (

        <AppBar
            style={{
                background:`${admin?"rgba(28, 43, 75, 0.1)": "rgba(28, 43, 75, 1)"}`
                ,
                boxShadow:`${admin && 'none'}`,
                
            }}
            position="static">

            <Container maxWidth="xl">
                <Toolbar disableGutters style={{ justifyContent: "space-between" }}>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {
                                xs: 'flex',
                                md: 'none',
                            }
                        }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {
                                    xs: 'block',
                                    md: 'none',
                                    zIndex: "1"

                                }
                            }}>
                            {pages.map((page) => {
                                if (page == "Services") {
                                    return (<DropDown TaskList={TaskList} mobile={true} />);
                                }
                                else if (page == "Courses") {
                                    return (<DropDownNavCourses TaskList={TaskList} mobile={true} />);
                                }
                                else {
                                    return (
                                        <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                                            <div >
                                                <Typography textAlign="center">{page.replace("-", " ")}</Typography>
                                            </div>
                                        </MenuItem>
                                    )
                                }
                            })}
                        </Menu>
                    </Box>


                    <div className="Logo cursor-pointer  h-10 ">
                        <img src={logo} alt="Logo" />

                    </div>
                    <Box
                        sx={{
                            display: {
                                xs: 'none',
                                md: 'flex'
                            }
                        }}>
                        {pages.map((page, idx) => {
                            if (page == "Services") {
                                return (<DropDown TaskList={TaskList} mobile={false} />);
                            }
                            else if (page == "Courses") {
                                return (<DropDownNavCourses TaskList={TaskList} mobile={false} />);
                            }
                            else {
                                return (
                                    <Button
                                        key={page}
                                        onClick={() => handleCloseNavMenu(page)}
                                        sx={{
                                            my: 2,
                                            mx: 2,
                                            color: 'white',
                                            display: 'block',
                                            fontWeight: 'bold',
                                            border: idx == Active
                                                ? "1px solid"
                                                : ""
                                        }}>
                                        {page.replace("-", " ")}
                                    </Button>) }
                        })}
                    </Box>
                    <SpringModal
                                text={
                                <>
                                <ShoppingCartIcon></ShoppingCartIcon>
                                      <div style={{
                        fontSize: '12px',
                        background: '#0f172a',
                        color: '#fff',
                        padding: '1.5px',
                        opacity:"0.8",
                        borderRadius: '50px'
                    }}>{Object.keys(cartList).length}</div>
                                </>
                            
                            
                            }
                                style={{
                                    padding: "0px !important"
                                }}>
                               <Cart></Cart>
                            </SpringModal>
                            {/* }}> */}
                    {/* </ShoppingCartIcon> */}
                    {/* <div style={{
                        fontSize: '12px',
                        background: '#0f172a',
                        border: "2px solid",
                        borderColor:"#fff",
                        color: '#fff',
                        padding: '0 5px',
                        verticalAlign: 'top',
                        marginLeft: '-10px',
                        paddingLeft: '9px',
                        paddingRight: '9px',
                        borderRadius: '45%'
                    }}>{Object.keys(cartList).length}</div> */}
                    <Box sx={{
                        flexGrow: 0
                    }}>
                        {Object.keys(userData).length !== 0
                            ? <Tooltip title="Open settings">
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                >
                                    <Avatar alt={userData.name?.toUpperCase()} src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            : <SpringModal
                                text="Login/ Sign Up"
                                style={{
                                    padding: "0px !important"
                                }}>
                                <Login></Login>
                            </SpringModal>}
                        <Menu
                            sx={{
                                mt: '45px'
                            }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}>
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    {
                                        (setting === "Admin" && userData.role == "admin") &&
                                        <Typography textAlign="center">{setting.replace("-", " ")}</Typography>
                                    }

                                    {
                                    setting !== "Admin" && <Typography textAlign="center">{setting.replace("-", " ")}</Typography>
                                    }                            
                                    {/* <Typography textAlign="center">{setting.replace("-", " ")}</Typography> */}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navbar;
