import { useState, useCallback } from 'react';
import { dispatch } from "../../redux/store";
import { authApi } from '../../services/api';
import { messageActions } from '../../redux/slices/message';

const useHttp = () => {
    const [ isLoading, setIsLoading ] = useState(false);

    const sendRequest = useCallback(async (requestConfig,applyFunction,successMessage) => {
        setIsLoading(true);
        try{
            const response = await authApi.request(requestConfig);
            if(response.status !== 200){
                dispatch(messageActions.setMessage({messageType: "error", message: response.data.message}))
            }
            if(response.status === 200){
                if(successMessage){
                    dispatch(messageActions.setMessage({messageType: "success", message: successMessage}))
                }
                if(applyFunction){
                    applyFunction(response.data)
                }
            }
        }catch(error){
        }
        setIsLoading(false);
    }, []);

    return {
        isLoading, sendRequest
    }
}

export default useHttp;