import React from 'react';
import Fade from 'react-reveal/Fade';
import './HomeContainer.style.scss'
import {Link} from 'react-router-dom';
function WhyUs({children}) {
    return (
        <div className="bg-white" style={{lineHeight:'28px'}}><div className="tag-line">
            <div className="">
                <div className="text-center" >

                    <div className="text-center bg-black">

                        <h2 className="font-bold text-3xl text-white"
                          style={{marginBottom:'20px', fontSize:"30px"}}>
                             WELCOME TO THE QUICK-RESPONSE  </h2>
                    </div>
                </div>
            </div>

        </div>

            <div id="features-sec" className="" >
                <div className="row text-center">
                    <div className="col-lg-8 col-lg-offset-2 col-md-8 col-sm-8 col-md-offset-2 col-sm-offset-2">
                        <h1 className="font-bold text-2xl"  style={{ marginBottom:'20px', fontSize:"30px"}}>WHY US? </h1>
                        <p>Besides affordable pricing for services offered, we provide following benefits to students:
                        </p>
                    </div>

                </div>


                <Fade bottom>
                    <div className="why-container flex p-5 w-full" >


                        <div className="px-5" >
                            <div className="rounded-full border-2 bg-slate-700 text-white p-10 text-center">
                                <i className="fa fa-paper-plane-o fa-3x rounded-full border-2 p-7 " ></i>
                                <h3 style={{ marginBottom:'10px'}}>QUALITY EDUCATION</h3>
                                <hr />
                                <hr />
                                <p >
                                    Highly qualified team of instructors with expertise in different areas have been trained to use learning methodologies and resources.

                                </p>
                               
                                <Link to="/contact" className=" font-bold underline">ASK THE EXPERT</Link>
                            </div>
                        </div>
                        <div className="px-5">
                            <div className="rounded-full border-2 bg-slate-700 text-white p-10 text-center">
                                <i className="fa fa-bolt fa-3x rounded-full border-2 p-7" ></i>
                                <h3 style={{fontFamily:'"Brush Script MT", cursive', marginBottom:'10px'}} >SYSTEMATIC APPROACH</h3>
                                <hr />
                                <hr />
                                <p >
                                    Experts follow a systematic process for teaching, i.e., analysis of current knowledge, delivering instructions and obtaining feedback.

                                </p>
                                
                                <Link to="/contact" className=" font-bold underline">ASK THE EXPERT</Link>
                            </div>
                        </div>
                        <div className="px-5">
                            <div className="rounded-full border-2 bg-slate-700 text-white p-10 text-center">
                                <i className="fa fa-magic fa-3x rounded-full border-2 p-7" ></i>
                                <h3 style={{fontFamily:'"Brush Script MT", cursive', marginBottom:'10px'}}>ONE TO ONE STUDY</h3>
                                <hr />
                                <hr />
                                <p >
                                    Dedicated time is allocated to each student to address their doubts and concerns.

                                </p>
                                
                                <Link to="/contact" className=" font-bold underline">ASK THE EXPERT</Link>
                            </div>
                        </div>


                    </div>
                </Fade>
            </div></div>
    )
}

export default WhyUs