import { Tab, Tabs } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import SignIn from "./SignIn.component"
import SignUp from "./SignUp.component"
import './Login.style.scss'
import MessageModal from "../../Components/MessageModal/MessageModal.component"
import { useSelector } from "react-redux"
import { dispatch } from "../../redux/store"
import { messageActions } from "../../redux/slices/message"

function Login() {
  const [TabValue, setTabValue] = useState(0);
  const messageType = useSelector((state => state.message.messageType))

  const message = useSelector((state => state.message.message))
function openModal(msg,mtype){
  dispatch(messageActions.setMessage({message: msg, messageType:mtype}))
}
  return (
    <>
        {/* <MessageModal
          status={messageType || 'success'}
          message={message}
          setMessage={() => dispatch(messageActions.clearMessage())}
        /> */}
      <div className='login-container  flex h-screen justify-center content-center items-start  overflow-hidden'>

        <div className=' bg-white flex justify-center'>

          <Box sx={{ margin: "20px" }}>
            <Tabs value={TabValue} onChange={() => { }} style={{ color: "green" }}>
              <Tab label="Login" onClick={() => setTabValue(0)} />
              <Tab label="Sign Up" onClick={() => setTabValue(1)} />
            </Tabs>
            {TabValue === 0 && <SignIn openModal={openModal} />}
            {TabValue === 1 && <SignUp openModal={openModal}/>}
          </Box>

        </div>
      </div>
    </>

  )
}

export default Login;