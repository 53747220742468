export const PRE_PROD_API_URL = "https://quick2response.com/api"
export const AUTH_TOKEN = "uId"
export const SESSION_EXPIRE_TIME = "SESSION_EXPIRE_TIME"

export const setItem = (key, value) => {
  return localStorage.setItem(key, value)
}
export const getItem = (key) => {
  return localStorage.getItem(key)
}
export const removeItem = (key) => {
  return localStorage.removeItem(key)
}
