
function Label({val}) {
  return (
    <div className="mx-4 font-semibold w-fit  p-2">

      <label>{val}</label>
    </div>
  )
}

export default Label