import Button from "../../Components/Button/Button.component"
import Input from "../../Components/Input/Input.component"
import Label from "../../Components/Label/Label.component"
import { useFormik } from 'formik'
import Error from "../../Components/Error/Error.component";
import { loginSchema } from "../../validationSchema";
import {authLogin} from '../../services/api';
import { useDispatch,useSelector } from "react-redux";
import { userDataActions } from "../../redux/slices/userData";
import { setItem } from "../../constants";
import { useNavigate } from "react-router-dom";

function SignIn({openModal}) {
  const navMe=useNavigate();
  const dispatch=useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async values => {
    let myRes= await authLogin(values);
    dispatch(userDataActions.setUserData(myRes.data.data));
    console.log(myRes);
    setItem('AUTH_TOKEN',myRes.data.token)
    navMe('/')
    // if(myRes.status==200)
    // openModal(myRes.data.message,'success')
    // else
    // openModal(myRes.data.message,'error')

     
    },
  });

  const myobj=useSelector((state=>state.userData.userData))
    console.log(myobj)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Label val='Email' />
      <Input
        placeholder={'Enter your Email'}
        name='email'
        type="email"
        onBlur={formik.handleBlur}
        value={formik.values.email}
        onChange={formik.handleChange}
      />
      {
        formik.touched.email &&
        <Error>{formik.errors.email}</Error>

      }
      <Label val='Password' />
      <Input
        placeholder={'Enter your Password'}
        name='password'
        type="password"
        onBlur={formik.handleBlur}
        value={formik.values.password}
        onChange={formik.handleChange}
      />
      {
        formik.touched.password &&
        <Error>{formik.errors.password}</Error>

      }
      
      
      <Button val="submit" isValid={!formik.dirty || !formik.isValid} />
    </form>
  )
}

export default SignIn