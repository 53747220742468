import { configureStore } from "@reduxjs/toolkit"

import messageReducer from "./slices/message"
import productReducer from "./slices/productSlice";
import userDataReducer from "./slices/userData";
import cartReducer from './slices/cartSlice';

const store = configureStore({
  reducer: {
    message: messageReducer,
    products:productReducer,
    userData:userDataReducer,
    cartItems:cartReducer,
  },
  devTools: process.env.NODE_ENV === "development",
})

export const dispatch = store.dispatch;
export default store;