import { useState } from 'react';
import { useEffect } from 'react';
import { authGetUsers } from '../../services/api'
import UsersList from './UsersList';

function Users() {
    const [userData, setUserData] = useState([])
    useEffect(() => {
        (
            async() => {
            let myRes = await authGetUsers();
            for (let i=0;i<myRes.data.data.length;i++){
                myRes.data.data[i].id=i+1
            }
            setUserData(myRes.data.data)
            console.log(myRes.data.data);        
        }
        )();
        
      
    
    }, [])

    return (
        <div><UsersList userData={userData} /></div>
    )
}

export default Users