import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
    name: "cartItems",
    initialState: {
        item:{

            price:0,
            task:"",
            subject:[],
            pages:0 ,
            deadline:"",
            desc:"",
            quesFileDoc:{}

        },
        fullCartItems:[]
    },
    reducers: {
        setItem: (state, {payload}) => {
            state.item[payload.key]=payload.value
        },
        setFullCartItems: (state) => {
            state.fullCartItems.push(state.item)
        },
        deleteItem:(state,payload) =>{
            state.fullCartItems.splice(payload.payload,1)
           
        },
        clearCart:(state) =>{
            state.fullCartItems=[]
            state.item={

                price:0,
                task:"",
                subject:[],
                pages:0 ,
                deadline:"",
                desc:"",
                quesFileDoc:{}
    
            }
           
        }
       
    }
})

export const cartActions = cartSlice.actions;
export default cartSlice.reducer
