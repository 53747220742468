import { createSlice } from "@reduxjs/toolkit";

export const messageSlice = createSlice({
    name: "message",
    initialState: {
        message: '',
        messageType: '',
        showMessage: false,
    },
    reducers: {
        setMessage: (state, {payload}) => {
            state.message = payload.message
            state.messageType = payload.messageType
            state.showMessage = true
        },
        clearMessage: (state) => {
            state.message = ''
            state.messageType = ''
            state.showMessage = false
        }
    }
})
export const messageActions = messageSlice.actions;
export default messageSlice.reducer
