import React, { Component } from 'react';   
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader  
import { Carousel } from 'react-responsive-carousel';  
import img1 from '../../assets/utils/1.jpg'
import img2 from '../../assets/utils/2.jpg'
import img3 from '../../assets/utils/3.jpg'
import img4 from '../../assets/utils/4.jpg'
function ImgCarousel() {
  return (<>
    <div style={{position:"fixed",zIndex:"-1"}}>
        <Carousel interval={5000} autoPlay={true} dynamicHeight={false} showThumbs={false} swipeable={true} infiniteLoop={true}> 
    <div>  
        <img src={img1} />  
        <p className="legend">Legend 1</p>  
    </div>  
    <div>  
        <img src={img2} />  
        <p className="legend">Legend 2</p>  
    </div>  
    <div>  
        <img src={img3} />  
        <p className="legend">Legend 3</p>  
    </div>  
    <div>  
        <img src={img4} />  
        <p className="legend">Legend 4</p>  
    </div> 
</Carousel> </div>
</>
  )
}

export default ImgCarousel