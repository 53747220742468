import { Alert } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { getNotification, readNotification } from "../../services/api";
import PaginationControlled from "./Pagination.component";

function Notification() {
    const [Notifications, setNotifications] = useState([])
    const [PgCount, setPgCount] = useState(0)
    const [page, setPage] = useState(1);

    let NotificationDescription = {
        'AddAssigned': 'You have assigned new Assigment By Admin',
        'RemovedAssigned': 'You have been removed from Assigment By Admin',
        'RemovedEnquiry': 'You have been removed from Assigment QUERY By Admin',
        'AddEnquiry': 'You have a new Assigment QUERY By Admin',
        'Message':'You Recieved a new Message'
    }
    const navigate = useNavigate();


    useEffect(() => {
        let Notify = async () => {

            let myRes = await getNotification();
            console.log(myRes.data.data);
            setNotifications([...myRes.data.data.reverse()])
            setPgCount(Math.ceil(myRes.data.data.length / 5))
        }
        Notify()
    }, [])

    return (
        <div>
            <div className=" w-full h-4/6">
                {
                    Notifications.map((val, idx) => {
                        if (Math.ceil((idx + 1) / 5) === page)
                            return (<div onClick={async () => {
                                await readNotification({ notificationId: val._id })

                                if (val.type == 'AddAssigned' || val.type == 'RemovedAssigned')
                                    navigate('/Assigned-Orders')
                                else if(val.type=='Message')
                                    navigate('/OrdersChats/'+val.refId)
                                
                                else
                                    navigate('/Order-Enquiry')


                            }}>
                                {
                                    !val.read ?
                                        <Alert severity="info" className=" cursor-pointer mt-5">
                                            {NotificationDescription[val.type] + '  '}
                                            <strong>{val.refId}</strong>
                                            <br />
                                            {val.createdAt.substr(0, 10) + ' ' + val.createdAt.substr(11, 8)}
                                        </Alert>
                                        :
                                        <Alert variant="outlined" severity="info">

                                            {NotificationDescription[val.type] + '  '}
                                            <strong>{val.refId}</strong>
                                            <br />
                                            {val.createdAt.substr(0, 10) + ' ' + val.createdAt.substr(11, 8)}
                                        </Alert>
                                }

                            </div>
                            )
                    })
                }
            </div>
            <PaginationControlled PgCount={PgCount} page={page} setPage={setPage} />
        </div>
    )
}

export default Notification